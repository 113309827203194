<div>
  <h2 class="h4">Newsgroup search</h2>
  <div>
    <div class="block">
      Search for a newsgroup that you are interested in. This search's results will include the newsgroup (or newsgroups) that most closely match your query as well as the Usenet servers
      which carry them.
      <div class="search">
        <div class="ui-widget">
          <input type="text" [(ngModel)]="query" (keyup)="autocomplete(query, $event)" *ngIf="flag" />
          <input type="text" [(ngModel)]="query" (keyup)="autocomplete(query, $event)" *ngIf="!flag" disabled />
          <button type="button" (click)="onSelectResult(query)">Search</button>
          <div *ngIf="flag">
            <ul>
              <li *ngFor="let result of results">
                <a (click)="onSelectResult(result)">{{result}}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
