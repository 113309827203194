import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-ard',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.css']
})

export class CardComponent implements OnInit {
  @Input() content: any;

  constructor() { }

  ngOnInit() {
  }

}
