import { Component, OnInit, Input } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { CardComponent } from '../card/card.component';
import { WordpressPosting } from './wordpress-posting';
import { WordpressPostingService } from './wordpress-posting.service';

@Component({
  selector: 'app-wordpress-posting',
  templateUrl: '../card/card.component.html',
  styleUrls: ['../card/card.component.css'],
  providers: [WordpressPostingService]
})

export class WordpressPostingComponent extends CardComponent implements OnInit {
  @Input() id: number;

  constructor(private wordpressPostingService: WordpressPostingService) {
    super();
  }

  ngOnInit() {
    this.wordpressPostingService.getWordpressPosting(this.id)
      .subscribe((data) => this.content = {
        title: `<a href="${data.permalink}">${data.title}</a>`,
        body: data.excerpt
      });
  }
}
