import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-review-link',
  templateUrl: './review-link.component.html',
  styleUrls: ['./review-link.component.css']
})
export class ReviewLinkComponent implements OnInit {
  @Input() internalLink: string;
  @Input() referralLink: string;
  @Input() description: string;

  constructor() { }

  ngOnInit() {
  }

}
