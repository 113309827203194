<!-- Fixed navbar -->
<nav class="navbar navbar-default navbar-expand-md fixed-top navbar-light bg-light">
  <a class="navbar-brand" href="/">Freenews</a>
  <button type="button" class="navbar-toggler" data-toggle="collapse" data-target="#top-navbar" aria-expanded="false" aria-controls="top-navbar" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div id="top-navbar" class="collapse navbar-collapse">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item">
        <a class="nav-link" href="/free-newsgroups">Free&nbsp;Trials</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="/usenet-reviews">Usenet&nbsp;Reviews</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="/newsgroup-search">Newsgroup&nbsp;Search</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="/free-usenet-account">Free&nbsp;Usenet&nbsp;Account</a>
      </li>
    </ul>
    <ul class="navbar-nav navbar-right">
      <li class="nav-item">
        <a class="nav-link" href="/faq">FAQ</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="/about">About Us</a>
      </li>
    </ul>
  </div>
</nav>
