<div class="row">
  <div class="col-lg-12 col-md-12 col-sm-12 col-12">
    <h2 class="h4">Personal Privacy</h2>
    <div class="block">
      <a href="/homelink/VYPRVPN">VyprVPN</a>
      <div>
        This application helps protect your privacy while using Usenet and comes free with Giganews Usenet's
        <a href="/homelink/GNDIAMOND">Diamond</a> and
        <a href="/homelink/GNPLATINUM">Platinum</a>
        plans
      </div>
    </div>
  </div>
</div>
