import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vpn',
  templateUrl: './vpn.component.html',
  styleUrls: ['./vpn.component.css']
})
export class VpnComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
