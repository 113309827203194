import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'freenews-newsgroup-search',
  templateUrl: './newsgroup-search.component.html',
  styleUrls: ['./newsgroup-search.component.css']
})
export class NewsgroupSearchComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
