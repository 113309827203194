import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { environment } from '../../environment';

import { MessageService } from '../../common/message.service';
import { IndexResults } from './index-results';

const httpOption = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
};

@Injectable({ providedIn: 'root' })
export class IndexResultService {
    url = environment.uri + '/XHR/indexresults.xml.pl';

    constructor(
        private http: HttpClient,
        private messageService: MessageService) { }

    getIndexResults(): Observable<IndexResults> {
        return this.http.get<IndexResults>(this.url)
            .pipe(
                tap(_ => this.log(`fetched index results`)),
                catchError(this.handleError<IndexResults>(`getIndexResults`))
            );
    }

    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            console.error(error);
            this.log(`${operation} failed: ${error.message}`);
            return of(result);
        };
    }

    private log(message: string) {
        this.messageService.add('IndexResultService: ' + message);
    }
}
