import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-free-trials',
  templateUrl: './free-trials.component.html',
  styleUrls: ['./free-trials.component.css']
})
export class FreeTrialsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
