import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-quick-searches',
  templateUrl: './quick-searches.component.html',
  styleUrls: ['./quick-searches.component.css']
})
export class QuickSearchesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
