<div class="row">
  <div class="col-12">
    <h2 class="h4">Newsgroup Indexing Statistics</h2>
    <div class="block">
      <a href="/newspage.html?showspeed=on&amp;sortby=speed">Fastest&nbsp;Active&nbsp;File</a>
      <br /> Fastest active download for me, your results may vary
    </div>
    <div class="block">
      <a href="/newspage.html?sortby=artspersec&amp;showartspersec=on">Most&nbsp;Articles/Second</a>
      <br /> Exponential Moving Average
    </div>
  </div>
</div>
