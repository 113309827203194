import { Component } from '@angular/core';
import { AppComponent } from './app.component';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.css'],
    providers: [AppComponent]
})

export class MenuComponent {
}
