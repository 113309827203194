import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { environment } from '../../environment';

import { MessageService } from '../../common/message.service';
import { LatestSearches } from './latest-searches';

const httpOption = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
};

@Injectable({ providedIn: 'root' })
export class LatestSearchService {
    url: string;

    constructor(
        private http: HttpClient,
        private messageService: MessageService) {
        this.url = environment.uri + '/XHR/latestsearches.xml.pl';
    }

    getLatestSearches(): Observable<LatestSearches> {
        return this.http.get<LatestSearches>(this.url)
            .pipe(
                tap(_ => this.log(`fetched latest results`)),
                catchError(this.handleError<LatestSearches>(`getLatestSearches`))
            );
    }

    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            console.error(error);
            this.log(`${operation} failed: ${error.message}`);

            return of(result);
        };
    }

    private log(message: string) {
        this.messageService.add('LatestSearchService: ' + message);
    }
}

