<div class="row">
  <div class="col-12">
    <h2 class="h4">Indexing Results</h2>
    <div *ngIf="results" class="text-center">
      <div class="d-inline-block col-8">
        <div *ngFor="let result of results.index" class="row m-0">
          <div class="col-10 border text-nowrap text-left">
            <a href="/newspage.html?date={{result.date}}">{{result.date}}</a>
          </div>
          <div class="col-2 border text-right p-0">{{result.count}}</div>
        </div>
      </div>
    </div>
  </div>
</div>
