<div class="row">
  <div class="col-12">
    <h2 class="h4">Newsgroup Browsers</h2>
    <div class="block">
      <a href="/reviews/giganews.htm">Giganews Usenet</a> has a newsgroup reader called
      <a href="/homelink/MIMO">MIMO Browser</a> which works directly with their servers to provide fast searches for Usenet articles. You can buy their browser or use it for free
      with any
      <a href="/homelink/GNDIAMOND">Giganews</a> account
    </div>
    <div class="block">
      <a href="/review/newshosting">Newshosting</a> has recently released their
      <a href="/reviews/reader/newshosting.htm">Newshosting Client</a>. The browser works directly with their servers to provide fast searches and thumbnails for Usenet articles
      and images. The browser is free to all new subscribers
    </div>
  </div>
</div>
