import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-indexing-statistics',
  templateUrl: './indexing-statistics.component.html',
  styleUrls: ['./indexing-statistics.component.css']
})
export class IndexingStatisticsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
