import { Component, OnInit } from '@angular/core';
import { ReviewService } from './review.service';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.css']
})

export class ReviewComponent implements OnInit {
  resultHtml: string;
  resultHtmlString: any;
  provider: string;
  content: any;

  constructor(
    private reviewService: ReviewService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.provider = params.get('provider');
      this.reviewService.getText(this.provider)
        .subscribe((data: string) => {
          data = data.replace('{{ provider }}', this.provider);
          this.content = this.sanitizer.bypassSecurityTrustHtml(data);
        });
    });
  }
}
