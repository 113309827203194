<div class="row">
  <div class="col-12">
    <h2 class="h4">Latest&nbsp;Searches</h2>
    This list contains newsgroups that users have searched for. Following the links gives a list of servers that provide access to the groups.
    <div *ngIf="results" class="text-center">
      <div class="border d-inline-block">
        <div *ngFor="let result of results?.searches" class="border text-left">
          <a href="/newsgroup-search?q={{result?.search}}">{{result?.search}}</a>
        </div>
      </div>
    </div>
  </div>
</div>
