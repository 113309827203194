<div>
  <h2 class="h4">FreeNews</h2>
  <div>
    <div class="block">
      Welcome to freenews.maxbaud.net the oldest
      <a href="/newsgroup-search">Newsgroup search</a> and
      <a href="/usenet-reviews">Usenet reviews</a> site on the internet. Our visitors have been searching for free nntp servers since before the internet was opened up to the general public. Today we continue
      to allow users to search for free Usenet servers and have also added commercial Usenet providers to our index.
    </div>
  </div>
</div>
