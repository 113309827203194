import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { MenuComponent } from './menu.component';
import { AppComponent } from './app.component';
import { CardComponent } from './card/card.component';
import { CardStackComponent } from './card-stack/card-stack.component';
import { WordpressPostingComponent } from './wordpress-posting/wordpress-posting.component';
import { AboutUsComponent } from './cards/about-us/about-us.component';
import { NewsgroupSearchFormComponent } from './cards/newsgroup-search-form/newsgroup-search-form.component';
import { FreeTrialsComponent } from './cards/free-trials/free-trials.component';
import { ReviewLinkComponent } from './review-link/review-link.component';
import { NewsgroupSpecialsComponent } from './cards/newsgroup-specials/newsgroup-specials.component';
import { QuickSearchesComponent } from './menus/quick-searches/quick-searches.component';
import { NavigationComponent } from './menus/navigation/navigation.component';
import { SocialComponent } from './social/social.component';
import { BestProvidersComponent } from './cards/best-providers/best-providers.component';
import { LatestSearchesComponent } from './cards/latest-searches/latest-searches.component';
import { BrowsersComponent } from './cards/browsers/browsers.component';
import { IndexingStatisticsComponent } from './cards/indexing-statistics/indexing-statistics.component';
import { IndexingResultsComponent } from './cards/indexing-results/indexing-results.component';
import { FormsModule } from '@angular/forms';
import { NewsgroupSearchService } from './cards/newsgroup-search-form/newsgroup-search-form.service';
import { WcgComponent } from './cards/wcg/wcg.component';
import { VpnComponent } from './cards/vpn/vpn.component';
import { NewsgroupSearchComponent } from './newsgroup-search/newsgroup-search.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TopNavBarComponent } from './top-nav-bar/top-nav-bar.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { ContactComponent } from './contact/contact.component';
import { AppRoutingModule } from './app-routing.module';
import { ReviewComponent } from './review/review.component';

@NgModule({
  declarations: [
    HomeComponent,
    MenuComponent,

    AppComponent,
    CardComponent,
    CardStackComponent,
    WordpressPostingComponent,
    AboutUsComponent,
    NewsgroupSearchFormComponent,
    FreeTrialsComponent,
    ReviewLinkComponent,
    NewsgroupSpecialsComponent,
    QuickSearchesComponent,
    NavigationComponent,
    SocialComponent,
    BestProvidersComponent,
    LatestSearchesComponent,
    BrowsersComponent,
    IndexingStatisticsComponent,
    IndexingResultsComponent,
    WcgComponent,
    VpnComponent,
    NewsgroupSearchComponent,
    TopNavBarComponent,
    PrivacyComponent,
    ContactComponent,
    ReviewComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    RouterModule,
    AppRoutingModule,
    BrowserAnimationsModule
  ],
  providers: [NewsgroupSearchService],
  bootstrap: [AppComponent]
})
export class AppModule { }
