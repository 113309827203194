import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-wcg',
  templateUrl: './wcg.component.html',
  styleUrls: ['./wcg.component.css']
})

export class WcgComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
