import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { MessageService } from '../../common/message.service';
import { tap, catchError, debounceTime } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class NewsgroupSearchService {
    url: string;

    constructor(
        private http: HttpClient,
        private messageService: MessageService) {
        this.url = '/XHR/groupac.py';
    }

    search(q: string): Observable<string[]> {
        return this.http.get<string[]>(this.url + '?term=' + q)
            .pipe(
                debounceTime(300),
                tap(_ => this.log(`fetched newsgroup autocomplete`)),
                catchError(this.handleError<string[]>(`search`))
            );
    }

    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            console.error(error);
            this.log(`${operation} failed: ${error.message}`);

            return of(result);
        };
    }

    private log(error: string) {
        this.messageService.add('NewsgroupSearchService: ' + error);
    }
}
