import { Component, OnInit, EventEmitter } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, catchError, map } from 'rxjs/operators';

import { NewsgroupSearchService } from './newsgroup-search-form.service';

@Component({
  selector: 'app-newsgroup-search',
  templateUrl: './newsgroup-search-form.component.html',
  styleUrls: ['./newsgroup-search-form.component.css']
})
export class NewsgroupSearchFormComponent implements OnInit {
  query: string;
  results: string[];
  search: Subject<string>;
  flag = true;

  constructor(
    private newsgroupSearchService: NewsgroupSearchService) {
    this.query = '';
    this.search = new Subject<string>();
  }

  ngOnInit() {
  }

  autocomplete(q: string, e: KeyboardEvent): void {
    if (q.length > 2) {
      if (13 === e.keyCode) {
        this.onSelectResult(q);
      } else {
        this.newsgroupSearchService.search(q)
          .subscribe((data) => {
            this.results = data;
          });
      }
    } else {
      this.results = [];
    }
  }

  onSelectResult(result: string) {
    this.query = result;
  }
}
