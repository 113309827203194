import { Component, OnInit } from '@angular/core';
import { LatestSearchService } from './latest-searches.service';
import { LatestSearches } from './latest-searches';

@Component({
  selector: 'app-latest-searches',
  templateUrl: './latest-searches.component.html',
  styleUrls: ['./latest-searches.component.css'],
  providers: [LatestSearchService]
})
export class LatestSearchesComponent implements OnInit {
  results: LatestSearches;

  constructor(private latestSearchService: LatestSearchService) { }

  ngOnInit() {
    this.getLatestSearches();

    setInterval(() => {
      this.getLatestSearches();
    }, 60000);
  }


  private getLatestSearches() {
    this.latestSearchService.getLatestSearches()
      .subscribe((data) => {
        this.results = data;
      });
  }
}
