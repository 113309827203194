import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../environment';

import { WordpressPosting } from './wordpress-posting';
import { MessageService } from '../common/message.service';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({ providedIn: 'root' })
export class WordpressPostingService {
  url = environment.uri + '/XHR/wordpress-post-json.php?';

  constructor(
    private http: HttpClient,
    private messageService: MessageService) { }

  getWordpressPosting(id: number): Observable<WordpressPosting> {
    const url = `${this.url}${id}`;
    return this.http.get<WordpressPosting>(url)
      .pipe(
        tap(_ => this.log(`fetched wordpress posting=${id}`)),
        catchError(this.handleError<WordpressPosting>(`getWordpressPosting=${id}`))
      );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      this.log(`${operation} failed: ${error.message}`);

      return of(result);
    };
  }

  private log(message: string) {
    this.messageService.add('WordpressPostingService: ' + message);
  }
}
