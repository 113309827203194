import { Component } from '@angular/core';
import { environment } from './environment';

@Component({
  selector: 'app-root',
  template: '<app-menu></app-menu><router-outlet></router-outlet>',
})
export class AppComponent {
  title = 'FreeNews Usenet Search Utilities & Newsgroup Server Reviews';
  tagline = 'The Original Newsgroup Search and Usenet Review Site on the Internet';
  sharethisId = environment.sharethis;

  constructor() {
  }
}
