<div class="contact">
    <h1>Contact Freenews</h1>
    <p>Last updated: December 23, 2022</p>
    <div>
        <h2>McCane Consulting, LLC</h2>
        <div>1400 SW Mitchell St<br />
            Oak Grove, MO 64075 Usage</div>
        <div><a href="mail:support@maxbaud.net">support@maxbaud.net</a></div>
    </div>
</div>
