import { Component, OnInit } from '@angular/core';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  providers: [AppComponent]
})
export class HomeComponent extends AppComponent implements OnInit {
  constructor(private appComponent: AppComponent) {
    super();
  }

  ngOnInit() {
  }
}
