<h2 class="h4">Newsgroup Specials</h2>
<div>
  <p>I have made deals with various Usenet servers to get special prices for my users. Please read the following reviews to see what their offers are.</p>
</div>
<div class="row">
  <div class="col-12 col-sm-12 col-md-12">
    <span class="notice">For a limited time</span>&nbsp;
    <a href="/reviews/giganews.htm">Giganews</a> is offering 50% off on their
    <a href="/homelink/GNDIAMOND">Giganews Diamond</a> account for your first 3 months, a $45 savings.
  </div>
  <div class="col-12 col-sm-12 col-md-12">
    <a href="/reviews/usenet.net.htm">Usenet.net</a> is offering a special monthly rate for the life of your account.
  </div>
  <div class="col-12 col-sm-12 col-md-12">
    <a href="/review/newshosting">Newshosting</a> is offering a special monthly rate for the life of your account.
  </div>
  <div class="col-12 col-sm-12 col-md-12">
    <a href="/reviews/usenetserver.htm">UsenetServer</a> is offering a special monthly rate and an amazing yearly rate for the life of your account.
  </div>
</div>
