import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-browsers',
  templateUrl: './browsers.component.html',
  styleUrls: ['./browsers.component.css']
})
export class BrowsersComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
