import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

export interface ResultHtml { }

@Injectable({
  providedIn: 'root'
})

export class ReviewService {
  constructor(private httpClient: HttpClient) { }

  getText(provider: string) {
    return this.httpClient.get(`/assets/reviews/${provider}`, { responseType: 'text' })
      .pipe(
        // if it fails, read in a a default review page with nil content
        catchError(error => this.httpClient.get('assets/reviews/nonexistent', { responseType: 'text' }))
      )
  }
}
