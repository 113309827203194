import { Component, OnInit } from '@angular/core';
import { IndexResultService } from './index-results.service';
import { IndexResults } from './index-results';

@Component({
  selector: 'app-indexing-results',
  templateUrl: './indexing-results.component.html',
  styleUrls: ['./indexing-results.component.css'],
  providers: [IndexResultService]
})

export class IndexingResultsComponent implements OnInit {
  results: IndexResults;

  constructor(private indexResultService: IndexResultService) { }

  ngOnInit() {
    this.getIndexResults();

    setInterval(() => {
      this.getIndexResults();
    }, 600000);
  }

  private getIndexResults() {
    this.indexResultService.getIndexResults()
      .subscribe((data) => {
        this.results = data;
      });
  }
}
