<div>
  <div class="row">
    <app-quick-searches class="col-12 col-md-6 d-none d-sm-block"></app-quick-searches>
    <app-navigation class="col-12 col-md-6 d-none d-sm-block"></app-navigation>
  </div>
  <div class="row">
    <div class="sharethis-inline-share-buttons col-12"></div>
  </div>
  <div class="row">
    <app-about-us class="col-12 col-md-6"></app-about-us>
    <app-newsgroup-specials class="col-12 col-md-6"></app-newsgroup-specials>
    <app-free-trials class="col-12 col-md-6"></app-free-trials>
    <app-newsgroup-search class="col-12 col-md-6"></app-newsgroup-search>
    <app-best-providers class="col-12 col-md-6"></app-best-providers>
    <app-latest-searches class="col-12 col-md-6"></app-latest-searches>
    <app-browsers class="col-12 col-md-6"></app-browsers>
    <app-wordpress-posting [id]='1' class="col-12 col-md-6"></app-wordpress-posting>
    <app-indexing-statistics class="col-12 col-md-6"></app-indexing-statistics>
    <app-wordpress-posting [id]='2' class="col-12 col-md-6"></app-wordpress-posting>
    <app-indexing-results class="col-12 col-md-6"></app-indexing-results>
    <app-wordpress-posting [id]='3' class="col-12 col-md-6"></app-wordpress-posting>
    <app-wcg class="col-12 col-md-6"></app-wcg>
    <app-wordpress-posting [id]='4' class="col-12 col-md-6"></app-wordpress-posting>
    <app-vpn class="col-12 col-md-6"></app-vpn>
    <app-wordpress-posting [id]='5' class="col-12 col-md-6"></app-wordpress-posting>
  </div>
</div>
